import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import NewsletterLeft from "../images/Newsletter-Left-Characters.png"
import NewsletterRight from "../images/Newsletter-Right-Characters.png"
import FlareSeparator from "./flare-separator"
import "../styles/newsletter.scss"

const NewsLetter = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email, { name })
      .then(data => {
        console.log(data)
        if (data.result === "success") {
          setMessage(data.msg)
          setSuccess(true)
        } else {
          setMessage(
            `${email} is already subscribed to list AGBO FANTASY FOOTBALL`
          )
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const changeEmailHandler = event => {
    setEmail(event.target.value)
  }
  const changeNameHandler = event => {
    setName(event.target.value)
  }
  return (
    <>
      <FlareSeparator />

      <div className="relative" id="newsletter">
        <div className="flex flex-col items-center justify-center w-4/5 mx-auto xl:w-3/5 xxxl:w-2/4">
          <h2 className="w-full pt-16 text-3xl lg:text-5xl xl:pt-56 xxl:pt-48">
            Stay Up On The Action
          </h2>
          <p className="mt-2 text-lg italic leading-7 text-center lg:text-xl fm-oswald ">
            Subscribe and get access to exclusive & early content for the AGBO
            Superhero League. AGBO Team Members will receive standings,
            matchups, weekly analysis’ from the league Commissioner and Matthew
            Berry, exclusive fantasy updates behind the scenes, and
            opportunities to vote on and make fantasy picks for the players.
            AGBO Team Members will also be the first to know about merchandise
            and get early access to limited edition collector’s items for sale!
            Plus you’ll get access to exclusive AGBO Superhero League content
            like raffles, prizes, early trash talk teasers, charity details and
            more!
          </p>
          <div className="w-full py-12 form md:w-1/2 xl:pb-0">
            {message !== "" && success ? (
              <p className="text-center ">{message}</p>
            ) : (
              <>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-center gap-4 newsletter xl:pb-0 xxl:gap-5 xxxl:gap-8"
                >
                  <input
                    type="text"
                    value={name}
                    onChange={changeNameHandler}
                    name="Name"
                    id="agbo-name"
                    className="block pl-4 mb-2 text-black newsletter-input"
                    placeholder="Name"
                    required
                  />
                  <input
                    type="email"
                    value={email}
                    onChange={changeEmailHandler}
                    name="Email"
                    id="agbo-email"
                    className="block pl-4 mb-2 text-black newsletter-input"
                    placeholder="Email-Address"
                    required
                  />
                  <button
                    type="submit"
                    className="inline-block px-4 py-1 mx-5 my-4 text-3xl text-center text-black uppercase bg-white rounded-md button-newsletter button lg:text-3xl"
                  >
                    Sign Up
                  </button>
                </form>
                <p className="text-center ">{message}</p>
              </>
            )}
          </div>
        </div>
        <img
          src={NewsletterRight}
          alt="NewsLetter Right"
          className="absolute top-0 right-0 hidden object-contain w-1/4 h-full xl:block"
        />
        <img
          src={NewsletterLeft}
          alt="NewsLetter Left"
          className="absolute top-0 left-0 hidden object-contain w-1/4 h-full xl:block"
        />
      </div>
    </>
  )
}

export default NewsLetter
